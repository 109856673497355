@font-face{
  font-family:'Unnamed-Light';
  src:url("./assets/fonts/Unnamed-Light.eot");
  src:url("./assets/fonts/Unnamed-Light.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/Unnamed-Light.woff2") format("woff2"),
  url("./assets/fonts/Unnamed-Light.woff") format("woff"),
  url("./assets/fonts/Unnamed-Light.ttf") format("truetype"),
  url("./assets/fonts/Unnamed-Light.svg#Unnamed-Light") format("svg");
  font-weight:normal;
  font-style:normal
}


/* Full-screen logo screen */
.logo-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 9999; /* Ensures it stays above all other content */
}

.logo-pulsating {
  animation: pulsate 5s ease-in-out;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 574px; /* Adjust size as needed */
  height: 70px;
}

@keyframes pulsate {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.spinner-container {
  display: block;
  height: 100%;
  position: absolute;
  top: 20px;
  left: 20px;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  color: #ffffff; /* You can customize the color */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  background-color: black;
}

.app {
  font-family:'Unnamed-Light', sans-serif;
  font-size: 1rem;
  position: relative;
  height: 100vh;
  width: 100vw;
  opacity: 0; /* Hidden initially */
  transition: opacity 2s ease; /* Smooth fade-in */
  background-image: url('./assets/hs82-bg-no-shadow-retina.jpg');
  background-image: -webkit-image-set(url('./assets/hs82-bg-no-shadow-retina.jpg') 1x, url('./assets/hs82-bg-no-shadow-retina.jpg') 2x);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  background-color: black;
}

h1, h2, h3, h4 {
  font-family:'Unnamed-Light', sans-serif;
  letter-spacing: 0.5px;
  font-weight: 300;
  line-height: 1.2;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 2rem;
}

.app.content-visible {
  opacity: 1; /* When content is visible, fade it in */
}

.image-for-mobile {
  display: none;
}


.logo {
  z-index: 2;
}

.logo img {
  display: block;
  height: 50px;
}

.logo {
  font-size: 0; /* Visually hide the text, but still keep it for SEO/screen readers */
  text-indent: -9999px; /* Move the text off-screen */
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.modal-open .logo img {
  opacity: 0.1;
}

.navigation {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 2;
}

.navigation .nav-link {
  margin-left: 50px;
  color: white;
  text-decoration: none;
  font-size: 1.7rem;
  background-color: transparent;
  border-style: none;
  font-family:'Unnamed-Light', sans-serif;
  padding: 0;
}

.navigation .nav-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.latest-album {
  z-index: 2;
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
  backdrop-filter: blur(3px); /* Apply background blur */
  -webkit-backdrop-filter: blur(3px); /* For Safari */
}

.album-container {
  display: flex;
  align-items: flex-end; /* Align album info to the top of the album cover */
  gap: 20px;
}

.album-cover {
  width: 200px;
  height: 200px;
}

.album-cover img {
  width: 200px; /* Set a fixed width for the album cover */
  height: 200px;
  margin-right: 20px; /* Add some space between the cover and the info */
  border-radius: 5px;
}

.album-info {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center album info vertically */
}

.album-info h2 {
  margin: 0;
  font-size: 1rem;
  color: #ccc;
}

.album-info h3 {
  margin: 0;
  font-size: 2rem;
  color: white;
}

.album-info p {
  margin: 0 0 20px 0;
  font-size: 1.3rem;
  color: white;
}

.buy-link {
  display: inline-flex;
  gap: 8px;
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  border-radius: 999px;
  transition: background-color 0.3s ease;
  border: 1px solid white;
  font-size: 1.2rem;
  justify-content: center;
  align-items: flex-start;
}

.buy-link:hover {
  background-color: #ffffff;
  color: black;
}

.skeleton-loader {
  width: 100%;
  height: 240px;
  background-color: transparent;
  animation: pulse 1.5s infinite ease-in-out;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  max-width: 400px;
}

.react-loading-skeleton {
  background-color: hsl(240, 3.7%, 15.9%);
  width: 100%;
  border-radius: 5px;
  display: inline-flex;
  line-height: 1;
  position: relative;
  user-select: none;
  overflow: hidden;
  animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
}

@keyframes pulse {
    50% {
        opacity: .4
    }
}


.song-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 0;
  margin-bottom: 20px;
}

.song-container .playButton {
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.song-container .songName {
  color: white;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 1);
  z-index: 1000;
}

.modal {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  padding: 80px 30px;
  background: black;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto; /* Enable scrolling for the modal content */
  box-sizing: border-box;
  position: relative;
  color: white;
  background: rgba(0, 0, 0, 1);

  font-family:'Unnamed-Light', sans-serif;
  font-size: 1.5rem;
}

.modal .max-width {
  max-width: 650px;
  margin: 0px auto;
  padding-top: 80px;
  padding-bottom: 80px;
}

/* Lock scroll when modal is open */
.modal-open {
  overflow: hidden;
}

/* When modal is open */
body.modal-open {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.modal ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.modal ul li {
  border-bottom: 1px solid #444;
  padding-bottom: 40px;
  padding-top: 40px;
}

.modal ul li:first-child {
  padding-top: 0;
}

.modal ul li:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.modal ul li p {
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 300;
  line-height: 1.5;
}

.modal ul li a {
  color: white;
}

.close-button {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: #ccc;
    z-index: 1;
  }

.modal ul li a {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  text-decoration: none;
}

.modal ul li a .right span {
  color: #ccc;
  font-size: 1.2rem;
}

.modal ul li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.modal h2 {
  text-align: left;
  margin-bottom: 60px;
  margin-top: 60px;
}

.modal h3 {
  margin-bottom: 0px;
}

.gigs-modal .buy-link {
  display: inline-flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1.1rem;
}

.gigs-modal .gig-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.gigs-modal span.icon {
  padding-top: 6px;
}

.gigs-modal .buy-link.disabled {
  color: #ccc;
  border: 1px solid #ccc;
}

.gigs-modal .buy-link.disabled:hover {
  color: #ccc;
  background-color: transparent;
}

.gigs-modal .date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: black;
  background-color: rgba(255, 255, 255, 1.0);
  border-radius: 5px;
}

.gigs-modal ul li {
  flex-direction: row;
  gap: 30px;
}

.gigs-modal ul li .right {
  font-size: 1.3rem;
  font-weight: 300;
}

.gigs-modal ul li .right p {
  font-size: 1.1rem;
}

.gigs-modal .date-container .date-month,
.gigs-modal .date-container .date-year {
  font-size: 1.1rem;
  text-transform: uppercase;
}

.gigs-modal .date-container .date-day {
  font-size: 2.5rem;
}

.gigs-modal ul li .right h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

.gigs-modal ul li .right .buy-link {
  margin-top: 20px;
}

.modal .videos-modal ul li a {
  align-items: flex-start;
}

.videos-modal ul li a .left {
    position: relative;
}

.videos-modal ul li a .left .word-play {
    position: absolute;
    top: 35%;
    left: 0%;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.videos-modal ul li a .left .word-play svg {
    margin-right: 5px;
}

.videos-modal ul li a .left .word-play {
    opacity: 0;
}

.videos-modal ul li a:hover .left .word-play {
  opacity: 1;
}

.videos-modal img {
  border-radius: 5px;
}

.modal .videos-modal ul li a .right span.views {
  font-size: 1rem;
  color: #ccc;
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
.modal .videos-modal ul li a .right span.views svg {
  margin-right: 5px;
}

.releases-modal img {
  border-radius: 5px;
}

.releases-modal ul li a .right span {
  font-size: 1rem;
}

.contact-modal .email {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 5px;
}

.contact-modal .contact-item p {
  margin-top: 20px;
}

.contact-modal .contact-item strong {
  color: #ccc;
}

.contact-modal .email a:hover {
  text-decoration: underline;
}

.contact-modal .email span.icon {
  padding-top: 2px;
}

.contact-modal ul li {
  border-bottom: 0;
  padding-bottom: 40px;
}

.contact-modal h4 {
  margin-bottom: 5px;
}

.contact-modal ul li:last-child {
  padding-top: 0px;
}

.modal .bottom-link {
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 999px;
  border: 1px solid white;
  color: white;
  background: transparent;
  text-decoration: none;
  font-size: 1.3rem;
  transition: background-color 0.3s ease;
}

.modal .bottom-link:hover {
  background-color: #ffffff;
  color: black;
}

footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons {
  z-index: 2;
}

.social-icons a {
  color: white;
  margin-left: 30px;
  transition: transform 0.3s ease;
  font-size: 0; /* Visually hide the text, but still keep it for SEO/screen readers */
  text-indent: -9999px; /* Move the text off-screen */
}

.social-icons a:first-child {
  margin-left: 0;
}

.social-icons a svg {
  transition: transform 0.3s ease;
}

.social-icons a:hover svg {
  transform: scale(1.1);
}

.contact-modal .email a .only-mobile{
    display: none;
}

.modal p {
  font-weight: 300;
  line-height: 1.5;
}

.newsletter-modal .subscribe-form {
  width: 100%;
  margin-top: 2rem;
}

.newsletter-modal .form-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.newsletter-modal .email-input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #262626;
  color: #ffffff;
  font-family:'Unnamed-Light', sans-serif;
  font-size: 1.2rem;
}

.newsletter-modal .email-input:focus {
  outline: none;
  border-color: #666;
}

.newsletter-modal .submit-button {
  padding: 0.75rem 1.5rem;
  background-color: #262626;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family:'Unnamed-Light', sans-serif;
  font-size: 1.2rem;
}

.newsletter-modal .submit-button:hover {
  background-color: #666;
}

.newsletter-modal .submit-button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.newsletter-modal .error-message {
  color: #ff4444;
  text-align: left;
  margin-top: 0.5rem;
}

.newsletter-modal .success-message {
  text-align: left;
  margin-top: 0.5rem;
}

.newsletter-modal .success-message p {
  color: #ffffff;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (min-width: 2900px) {
  .app {
    background-position: center -350px;
  }
  footer {
    justify-content: flex-start;
  }
}


@media (max-width: 1440px) {
  .modal .max-width {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 1280px) {
  .navigation .nav-link {
    margin-left: 50px;
  }
}

@media (max-width: 1024px) {

  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    background: linear-gradient(135deg, #C50A18, #F82F27, #FE4238);
    background-attachment: fixed;
    background-size: cover;
  }

  #root, .content {
    min-height: 100vh;
  }

  .logo-pulsating {
    height: 35px;
    width: 288px;
    animation: pulsate 5s ease-in-out;
  }

  .logo img {
    width: 100%;
    max-width: 400px;
    height: auto;
  }

  .album-container {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    width: 100%;
  }

  .navigation .nav-link {
    margin-left: 40px;
    margin-bottom: 15px;
  }

  .navigation .nav-link:first-child {
    margin-left: 0px;
  }

  .app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background-image: none;
    width: 100%;
    height: 100vh;
    background: transparent;
  }

  .album-cover img {
    margin-right: 0;
  }

  .image-for-mobile {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .image-for-mobile img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .social-icons a svg {
    width: 32px;
    height: 32px;
  }

  .logo, .navigation, .latest-album, .social-icons {
    position: static;
    margin: 20px;
  }

  .modal {
    padding: 30px;
    font-size: 1.3rem;
  }

  .videos-modal ul li a {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .videos-modal ul li a img {
    width: 100%;
    height: auto;
  }

  .videos-modal ul li a .left .word-play {
    top: 40%;
  }

  .gigs-modal .buy-link {
    font-size: 1rem;
  }

  .gigs-modal ul li {
    align-items: flex-start;
    flex-direction: column;
  }

  .releases-modal ul li a {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-modal .email a .only-mobile{
    display: block;
  }

  .contact-modal .email a .email-handle{
    display: none;
  }
}

@media (max-width: 768px) {
  .navigation .nav-link {
    display: block;
    margin-left: 0;
    text-align: center;
    width: 100%;
  }
  .newsletter-modal .form-group {
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .social-icons a {
    margin-left: 15px;
  }
  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.5rem;
  }
}